@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Roboto:wght@100;400;500;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Bad+Script&family=Kavivanar&family=Satisfy&display=swap');
body {
  min-height: 100vh;
  /* font-family: 'Roboto', sans-serif; */
}
.App {
  position: relative;
  padding-bottom: 12em;
  min-height: 100vh;
}
h1 {
  /* font-family: "Pacifico", cursive; */
  font-family: 'Amatic SC', cursive;

  font-weight: 700;
  
}
.pacifico {
  font-family: "Pacifico", cursive;

}
.roboto {
  font-family: 'Roboto', sans-serif;
}
.landing-title {
  font-size: 50pt;
  font-family: 'Amatic SC', cursive;

  padding: 20px;
  border-radius: 20px;
  padding-bottom: 40px;
}
h2 {
  font-family: 'Amatic SC', cursive;
  font-size: 40pt;
  font-weight: 700;
  /* font-family: "Pacifico", cursive; */
}
h3 {
  /* font-family: "Pacifico", cursive; */
  /* font-family: "Kavivanar", cursive; */

  /* font-size: x-large; */
}
.text-blue {
  color: #013d80;
}
.bg-blue {
  background-color: #1c5d82;
}
h6 {
  font-family: "Bad Script", cursive;
  font-size: xx-large;
  font-weight: 900;
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.footer-links {
  text-decoration: none;
  color: #e9e9e9;
  transition: 500ms;
}
.footer-links:hover {
  color: #2a83b6;

  text-decoration: none;
}
.contact-text {
  color: #92140c;
  font-size: large;
  text-align: center;
}
.contact-img {
  width: 30%;
}
.location-img {
  object-fit: cover;
}

.inst-img {
  max-width: 90%;
}

.parent-pic {
  width: 100%;
  height: 400px;
  object-fit: scale-down;
}